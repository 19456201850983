<template>
  <div>
    <loading :active.sync="isLoading"
             :can-cancel="false"
             :is-full-page="fullPage"></loading>
    <div class="row justify-content-md-center">
      <div class="col-12">
        <filter-side-bar>
          <template slot="ContentSideBar">
            <!-- Filtro por período -->
            <ul class="nav line-tabs borderless flex-center mb-5">
              <li class="nav-item">
                <a class="nav-link" :class="{'active': filters.filPeriodo === 'weekly'}" v-on:click="setTempPeriod('weekly')" ref="view_type_weekly" data-toggle="pill" data-period="weekly">{{ $t('period.weekly') }}</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" :class="{'active': filters.filPeriodo === 'monthly'}" v-on:click="setTempPeriod('monthly')" ref="view_type_monthly"  data-toggle="pill" data-period="monthly">{{ $t('period.monthly') }}</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" :class="{'active': filters.filPeriodo === 'yearly'}" v-on:click="setTempPeriod('yearly')"  ref="view_type_yearly" data-toggle="pill" data-period="yearly">{{ $t('period.yearly') }}</a>
              </li>
            </ul>

            <!-- Filtros -->
            <h5 class="mb-0">Filtrar por:</h5>
            <form id="report-statement-weekly-list-filter" v-on:submit.prevent="applyFilter()">

              <!-- Período -->
              <h6 class="mb-3 mt-5">{{ $t('kpi.volume.period') }}</h6>
              <div class="row">
                <div class="col-md-12">
                  <div class="md-form mb-0 row">
                    <div class="col-md-6">
                      <div class="md-form mb-0">
                        <select class="md-form-control" :value="filters.filYear" id="year" name="year" ref="year" >
                          <option v-for="(item, index) in reportData.years" v-bind:key="index" :value="item" :selected="filters.filYear === item" >{{ item }}</option>
                        </select>
                        <label :class="{'active': true}">{{ $t('form.report.year') }}</label>
                      </div>
                    </div>
                    <div class="col-md-6" v-if="tempPeriod == 'weekly'">
                      <div class="md-form mb-0">
                        <select class="md-form-control" :value="filters.filWeek" id="week" name="week" ref="week" >
                          <option v-for="(week, index) in reportData.weeks" v-bind:key="index" :value="index" :selected="filters.filWeek === parseInt(index)" >{{ week }}</option>
                        </select>
                        <label :class="{'active': true}">{{ $t('form.report.week') }}</label>
                      </div>
                    </div>
                    <div class="col-md-6" v-if="tempPeriod == 'monthly'">
                      <div class="md-form mb-0">
                        <select class="md-form-control" :value="filters.filMonth" id="month" name="month" ref="month" >
                          <option v-for="(month, index) in reportData.months" v-bind:key="index" :value="index" :selected="filters.filMonth === parseInt(index)" >{{ month}}</option>
                        </select>
                        <label :class="{'active': true}">{{ $t('form.report.month') }}</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="py-1 text-center">
                <div class="md-form mb-0">
                  <div class="row">
                    <div class="col-6">
                      <button type="submit" class="btn btn-primary btn-fill btn-block mr-2 ">{{ $t('filter.apply') }}</button>
                    </div>
                    <div class="col-6">
                      <button type="button"  v-on:click="resetFilter" class="btn btn-cancel btn-fill btn-block mr-2 ">{{ $t('filter.reset') }}</button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </template>
        </filter-side-bar>
      </div>

      <!-- Bloco com os Filtros -->
      <div class="col-12 mb-3">
        <filter-container :activeFilterList="getActiveFilterList" />
      </div>
      <div class="col-12 mt-2">
        <h5 >{{ getPeriodDescription }}</h5>
      </div>

      <!--   Vendedores Externos   -->
      <div class="col-12 mt-2">
        <h5 class="subtitle">Vendedores Externos</h5>
      </div>
      <div class="col-12 col-sm-6 col-md-6">
        <summary-box title="Faturamento" :value="reportData.vendedoresExternos.faturamento.atual" prefix="" sufix="%"
          :percent="((reportData.vendedoresExternos.faturamento.anterior - reportData.vendedoresExternos.faturamento.atual)
          / reportData.vendedoresExternos.faturamento.anterior * 100) * -1"
          :description="getDescription(reportData.vendedoresExternos.faturamento.atual, reportData.vendedoresExternos.faturamento.anterior, 'faturamento', '')"
        />
      </div>
      <div class="col-12 col-sm-6 col-md-6">
        <summary-box title="Visitas" :value="reportData.vendedoresExternos.visitas.atual" prefix=""
          :percent="((reportData.vendedoresExternos.visitas.anterior - reportData.vendedoresExternos.visitas.atual)
          / reportData.vendedoresExternos.visitas.anterior * 100) * -1"
          :description="getDescription(reportData.vendedoresExternos.visitas.atual, reportData.vendedoresExternos.visitas.anterior, 'visitas', '')"
        />
      </div>

      <div class="col-12 col-sm-6 col-md-6">
        <summary-box title="Ligações" :value="reportData.vendedoresExternos.ligacoes.atual" prefix=""
          :percent="((reportData.vendedoresExternos.ligacoes.anterior - reportData.vendedoresExternos.ligacoes.atual)
          / reportData.vendedoresExternos.ligacoes.anterior * 100) * -1"
          :description="getDescription(reportData.vendedoresExternos.ligacoes.atual, reportData.vendedoresExternos.ligacoes.anterior, 'ligações', '')"
        />
      </div>
      <div class="col-12 col-sm-6 col-md-6">
      </div>

      <!--   Inside Sales   -->
      <div class="col-12 mt-5">
        <h5 class="subtitle">Inside Sales</h5>
      </div>
      <div class="col-12 col-sm-6 col-md-6">
        <summary-box title="Quantidade de Cotações" :value="reportData.insideSales.quantidadeCotacoes.atual" prefix=""
          :percent="((reportData.insideSales.quantidadeCotacoes.anterior - reportData.insideSales.quantidadeCotacoes.atual)
          / reportData.insideSales.quantidadeCotacoes.anterior * 100) * -1"
          :description="getDescription(
            reportData.insideSales.quantidadeCotacoes.atual,
            reportData.insideSales.quantidadeCotacoes.anterior,
            'cotações', '')"
        />
      </div>

      <div class="col-12 col-sm-6 col-md-6">
        <summary-box title="Aproveitamento de propostas" :value="reportData.insideSales.aproveitamentoPropostas.atual" prefix="" sufix="%"
          :percent="((reportData.insideSales.aproveitamentoPropostas.anterior - reportData.insideSales.aproveitamentoPropostas.atual)
          / reportData.insideSales.aproveitamentoPropostas.anterior * 100) * -1"
          :description="getDescription(
            reportData.insideSales.aproveitamentoPropostas.atual,
            reportData.insideSales.aproveitamentoPropostas.anterior,
            'aproveitamento de propostas,', '')"
        />
      </div>

      <div class="col-12 col-sm-6 col-md-6">
        <summary-box title="Ligações" :value="reportData.insideSales.ligacoes.atual" prefix=""
          :percent="((reportData.insideSales.ligacoes.anterior - reportData.insideSales.ligacoes.atual)
          / reportData.insideSales.ligacoes.anterior * 100) * -1"
          :description="getDescription(
            reportData.insideSales.ligacoes.atual,
            reportData.insideSales.ligacoes.anterior,
            'ligações', '')"
        />
      </div>

      <div class="col-12 col-sm-6 col-md-6">
        <summary-box title="Tarifários Enviados" :value="getTotal(reportData.insideSales.tarifarios.atual)" prefix=""
          :percent="((getTotal(reportData.insideSales.tarifarios.anterior) - getTotal(reportData.insideSales.tarifarios.atual))
          / getTotal(reportData.insideSales.tarifarios.anterior) * 100) * -1"
          :description="getDescription(
            getTotal(reportData.insideSales.tarifarios.atual),
            getTotal(reportData.insideSales.tarifarios.anterior),
            'tarifarios enviados', '')"
        />
      </div>

      <div class="col-12 col-sm-6 col-md-6">
        &nbsp;
      </div>
      <!--   Detalhes (Tarifários)   -->
      <div class="col-md-12">
        <div class="card">
          <div class="card-title">
            <h6>Detalhes - Tarifários</h6>
          </div>
          <div class="card-body">
            <LocalDataVueTable :fields="fieldsTable" :data="tarifariosInsideSales"  trackBy="nome" no-data-template="Nenhum tarifário enviado no período." />
          </div>
        </div>
      </div>

      <!--   Analistas comerciais   -->
      <div class="col-12 mt-5">
        <h5 class="subtitle">Analistas Comerciais</h5>
      </div>
      <div class="col-12 col-sm-6 col-md-6">
        <summary-box title="Quantidade de processos abertos" :value="reportData.analistasComerciais.quantidadeProcessosAbertos.atual" prefix=""
          :percent="((reportData.analistasComerciais.quantidadeProcessosAbertos.anterior - reportData.analistasComerciais.quantidadeProcessosAbertos.atual)
          / reportData.analistasComerciais.quantidadeProcessosAbertos.anterior * 100) * -1"
          :description="getDescription(
            reportData.analistasComerciais.quantidadeProcessosAbertos.atual,
            reportData.analistasComerciais.quantidadeProcessosAbertos.anterior,
            'processos abertos', '')"
        />
      </div>
      <div class="col-12 col-sm-6 col-md-6">
        <summary-box title="Quantidade de revisões" :value="reportData.analistasComerciais.quantidadeRevisoes.atual" prefix=""
          :percent="((reportData.analistasComerciais.quantidadeRevisoes.anterior - reportData.analistasComerciais.quantidadeRevisoes.atual)
          / reportData.analistasComerciais.quantidadeRevisoes.anterior * 100) * -1"
          :description="getDescription(
            reportData.analistasComerciais.quantidadeRevisoes.atual,
            reportData.analistasComerciais.quantidadeRevisoes.anterior,
            'quantidade de revisões', '')"
        />
      </div>

      <div class="col-12 col-sm-6 col-md-6">
        &nbsp;
      </div>

      <!--  Pricing   -->
      <div class="col-12 mt-5">
        <h5 class="subtitle">Pricing</h5>
      </div>

      <div class="col-12 col-sm-6 col-md-6">
        <summary-box title="Aproveitamento de propostas" :value="reportData.pricing.aproveitamentoPropostas.atual" prefix="" sufix="%"
          :percent="((reportData.pricing.aproveitamentoPropostas.anterior - reportData.pricing.aproveitamentoPropostas.atual)
          / reportData.pricing.aproveitamentoPropostas.anterior * 100) * -1"
          :description="getDescription(
            reportData.pricing.aproveitamentoPropostas.atual,
            reportData.pricing.aproveitamentoPropostas.anterior,
            'aproveitamento de propostas', '')"
        />
      </div>

      <div class="col-12 col-sm-6 col-md-6">
        <summary-box title="Tempo de resposta das cotações para o Comercial/Inside" :value="getTotalDuracao(reportData.pricing.tempoResposta.atual)" :value-formatter="showDuracao"  prefix=""
          :percent="((getTotalDuracao(reportData.pricing.tempoResposta.anterior) - getTotalDuracao(reportData.pricing.tempoResposta.atual))
          / getTotalDuracao(reportData.pricing.tempoResposta.anterior) * 100) * -1"
          :description="getDescription(
            getTotalDuracao(reportData.pricing.tempoResposta.atual),
            getTotalDuracao(reportData.pricing.tempoResposta.anterior),
            'tempo de resposta', '', showDuracao)"
        />
      </div>

      <div class="col-12 col-sm-6 col-md-6">
        &nbsp;
      </div>
      <!--   Detalhes    -->
      <!--  Tempo de Reposta     -->
      <div class="col-md-12">
        <div class="card">
          <div class="card-title">
            <h6>Detalhes - Tempo de resposta das cotações para o Comercial/Inside</h6>
          </div>
          <div class="card-body">
            <LocalDataVueTable :fields="fieldsTableDuracao" :data="tempoRespostaPricing"  trackBy="nome" no-data-template="Nenhuma resposta no período." />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line
import { util } from '@/utils/utils.js'

// Components
import SummaryBox from '@/components/Basic/SummaryBox'
import FilterSideBar from '@/components/FilterSideBar.vue'
import FilterContainer from '@/components/FilterContainer'
import LocalDataVueTable from '@/components/Table/LocalDataVueTable'

// Loading
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'

// DatePicker
// eslint-disable-next-line
import '@/assets/vendor/bootstrap-datepicker/dist/js/bootstrap-datepicker.min.js'
// eslint-disable-next-line
import '@/assets/vendor/bootstrap-datepicker/dist/locales/bootstrap-datepicker.pt-BR.min.js'
import '@/assets/vendor/bootstrap-datepicker/dist/css/bootstrap-datepicker3.min.css'

// Services
import EmployeeService from '@/services/EmployeeService'
import ReportService from '@/services/ReportService'

export default {
  name: 'ReportIndicatorsCommercial',
  metaInfo () {
    return {
      titleTemplate: this.$i18n.t('report.title') + ' ' + this.$i18n.t('report.statement.financial.title') + ' - %s'
    }
  },
  data () {
    return {
      tempPeriod: 'weekly',
      employeeList: [],
      seller: {},
      tarifariosInsideSales: {
        data: []
      },
      tempoRespostaPricing: {
        data: []
      },
      reportData: {
        data: [],
        vendedoresExternos: {
          faturamento: {
            atual: 0,
            anterior: 0
          },
          visitas: {
            atual: 0,
            anterior: 0
          },
          ligacoes: {
            atual: 0,
            anterior: 0
          }
        },
        insideSales: {
          quantidadeCotacoes: {
            atual: 0,
            anterior: 0
          },
          aproveitamentoPropostas: {
            atual: 0,
            anterior: 0
          },
          ligacoes: {
            atual: 0,
            anterior: 0
          },
          tarifarios: {
            atual: 0,
            anterior: 0
          }
        },
        analistasComerciais: {
          quantidadeProcessosAbertos: {
            atual: 0,
            anterior: 0
          },
          quantidadeRevisoes: {
            atual: 0,
            anterior: 0
          }
        },
        pricing: {
          aproveitamentoPropostas: {
            atual: 0,
            anterior: 0
          },
          tempoResposta: {
            atual: [],
            anterior: []
          }
        }
      },
      isLoading: true,
      fullPage: true,
      activeFilterList: {
        period: null,
        employees: null,
        year: null,
        week: null,
        month: null
      },
      filter: {}
    }
  },
  components: {
    FilterSideBar,
    Loading,
    FilterContainer,
    SummaryBox,
    LocalDataVueTable
  },
  beforeMount () {
    /**
     * Returns the week number for this date.  dowOffset is the day of week the week
     * "starts" on for your locale - it can be from 0 to 6. If dowOffset is 1 (Monday),
     * the week returned is the ISO 8601 week number.
     * @param int dowOffset
     * @return int
     */
    // eslint-disable-next-line
    Date.prototype.getWeek = function (dowOffset) {
      // default dowOffset to zero
      dowOffset = dowOffset !== undefined && typeof (dowOffset) === 'number' ? dowOffset : 0
      let newYear = new Date(this.getFullYear(), 0, 1)
      // the day of week the year begins on
      let day = newYear.getDay() - dowOffset
      day = (day >= 0 ? day : day + 7)
      let daynum = Math.floor((this.getTime() - newYear.getTime() -
        (this.getTimezoneOffset() - newYear.getTimezoneOffset()) * 60000) / 86400000) + 1
      let weeknum
      // if the year starts before the middle of a week
      if (day < 4) {
        weeknum = Math.floor((daynum + day - 1) / 7) + 1
        if (weeknum > 52) {
          let nYear = new Date(this.getFullYear() + 1, 0, 1)
          let nday = nYear.getDay() - dowOffset
          nday = nday >= 0 ? nday : nday + 7
          // if the next year starts before the middle of the week, it is week #1 of that year
          weeknum = nday < 4 ? 1 : 53
        }
      } else {
        weeknum = Math.floor((daynum + day - 1) / 7)
      }
      return weeknum
    }
    this.buildFilters()
  },
  mounted () {
    this.$store.dispatch('SetShowFilter', true)
    this.$store.dispatch('setShowSearch', false)

    if (this.user) {
      this.getReportStatementData()
    }

    this.$jQuery('#begin_date, #end_date').datepicker({
      language: 'pt-BR',
      calendarWeeks: true,
      autoclose: true,
      templates: {
        leftArrow: '<i class="ti-angle-left"></i>',
        rightArrow: '<i class="ti-angle-right"></i>'
      }
    })
  },
  methods: {
    showDuracao (duracao) {
      if (duracao === undefined || isNaN(duracao)) {
        return '00:00'
      }

      duracao = Math.floor(duracao)
      let horas = Math.floor(duracao / 60)
      let minutos = duracao % 60

      if (horas < 0) {
        horas = horas * -1.0
      }

      if (minutos < 0) {
        minutos = minutos * -1.0
      }

      return horas.toString().padStart(2, '0') + ':' + minutos.toString().padStart(2, '0')
    },
    getTotal (desvios) {
      let total = 0

      for (let i in desvios) {
        let desvioGrupo = desvios[i]
        total += desvioGrupo.qtd
      }

      return total
    },
    getTotalDuracao (duracao) {
      let count = 0
      let total = 0

      for (let i in duracao) {
        let duracaoGrupo = duracao[i]
        total += duracaoGrupo.total_minutos
        count += duracaoGrupo.n_cotacoes
      }

      return total / count
    },
    onLoading () {
      this.isLoading = true
    },
    onLoaded () {
      this.isLoading = false
    },
    handleLoadError (response) {
    },
    setTempPeriod (item) {
      this.tempPeriod = item
    },
    getDescription (current, previous, label, prefix, valueFormatter) {
      if (current === undefined || isNaN(current)) {
        current = 0
      }

      if (previous === undefined || isNaN(previous)) {
        previous = 0
      }

      let lessThanInfo = 'na semana anterior.'
      let equalInfo = 'a semana anterior.'

      if (this.filter.period === 'yearly') {
        lessThanInfo = 'no ano anterior.'
        equalInfo = 'o ano anterior.'
      } else if (this.filter.period === 'monthly') {
        lessThanInfo = 'no mês anterior.'
        equalInfo = 'o mês anterior.'
      }

      let diferenca = current - previous
      prefix = prefix || ''
      if ((diferenca) > 0) {
        return prefix + ((valueFormatter) ? valueFormatter(diferenca) : this.showNumber(diferenca)) + ' ' + label + ' a mais que ' + lessThanInfo
      } else if ((diferenca) === 0) {
        return prefix + ' ' + label + ' iguais ' + equalInfo
      } else {
        return prefix + ((valueFormatter) ? valueFormatter(diferenca) : this.showNumber(diferenca * -1.0)) + ' ' + label + ' a menos que ' + lessThanInfo
      }
    },
    buildFilters () {
      this.filter.period = this.filters.filPeriodo
      this.tempPeriod = this.filters.filPeriodo

      /**
       *  Não primeira abertura seta o filtros com o padrão
       *   - Mês: Atual
       *   - Ano: Atual
       *   - Colaborador: Usuário autenticado
       *  */

      let today = new Date()
      if (!this.filters.filYear && !this.filters.filWeek && !this.filters.filMonth && (!this.filters.filEmployees || this.filters.filEmployees.length <= 0)) {
        this.filters.filYear = today.getFullYear()
        this.filters.filWeek = today.getWeek()
        this.filters.filMonth = today.getMonth() + 1

        this.$store.dispatch('setAppliedFilter', this.filters)
      }

      if ((!this.filters.filWeek)) {
        this.filters.filWeek = today.getWeek()
      }

      if ((!this.filters.filMonth)) {
        this.filters.filMonth = today.getMonth() + 1
      }

      this.filter.employees = (this.filters.filEmployees) ? this.filters.filEmployees.map(x => (x.id)) : []
      this.filter.year = this.filters.filYear
      this.filter.week = this.filters.filWeek
      this.filter.month = this.filters.filMonth
    },
    // Filter
    applyFilter () {
      this.employeeList = []
      // Period
      if (this.$refs.view_type_weekly.classList.contains('active')) {
        this.filters.filPeriodo = 'weekly'
      } else if (this.$refs.view_type_monthly.classList.contains('active')) {
        this.filters.filPeriodo = 'monthly'
      } else if (this.$refs.view_type_yearly.classList.contains('active')) {
        this.filters.filPeriodo = 'yearly'
      }

      // Filter Processes
      this.filters.filYear = this.$refs.year ? parseInt(this.$refs.year.value) : null
      this.filters.filWeek = this.$refs.week ? parseInt(this.$refs.week.value) : null
      this.filters.filMonth = this.$refs.month ? parseInt(this.$refs.month.value) : null

      this.buildFilters()

      this.$store.dispatch('setAppliedFilter', this.filters)

      this.hideQuichSidebar()

      this.getReportStatementData()
      /* Todo: Reload Charts */
      // this.getReportStatementData()
    },
    resetFilter (event) {
      this.hideQuichSidebar()
      // hasFilter: false
      let today = new Date()

      this.filters.filYear = today.getFullYear()
      this.filters.filWeek = today.getWeek()
      this.filters.filMonth = today.getMonth() + 1
      this.filters.filPeriodo = 'weekly'
      this.filters.filEmployees = []

      this.$store.dispatch('setAppliedFilter', this.filters)

      this.buildFilters()

      this.getReportStatementData()
    },
    hideQuichSidebar () {
      const sideBar = document.querySelector('#quick-sidebar')
      document.body.classList.remove('has-backdrop')
      sideBar.classList.remove('shined')
    },
    numberFormat (number, decimals, decPoint, thousandsSep) {
      return util.numberFormat(number, decimals, decPoint, thousandsSep)
    },
    showNumber (number, thousandsSep, defaultValue) {
      defaultValue = (defaultValue !== undefined) ? defaultValue : 0
      let decimals = 0
      let decPoint = ','
      thousandsSep = thousandsSep || '.'
      if (number !== parseInt(number)) {
        decimals = 2
      }

      if (number) {
        return this.numberFormat(number, decimals, decPoint, thousandsSep)
      }

      return defaultValue
    },
    showInteger (number, thousandsSep, defaultValue) {
      defaultValue = (defaultValue !== undefined) ? defaultValue : 0
      let decimals = 0
      let decPoint = ','

      if (number) {
        return this.numberFormat(number, decimals, decPoint, thousandsSep)
      }

      return defaultValue
    },
    // End Filter
    getReportStatementData () {
      let me = this
      me.isLoading = true

      if (this.$store.getters.userInfo) {
        ReportService.getIndicatorCommercial(this.filter.year, this.filter.week, this.filter).then(res => {
          me.seller = res.data.seller
          me.reportData = res.data

          me.tarifariosInsideSales = {
            data: res.data.insideSales.tarifarios.atual
          }

          me.tempoRespostaPricing = {
            data: res.data.pricing.tempoResposta.atual
          }
          me.isLoading = false
        })
      }
    },
    generateImage (value) {
      let baseUrl = process.env.VUE_APP_BaseURL + '/'

      let imageUrl = baseUrl + 'static/images/customers/no_photo.jpeg'
      if (value) {
        let fileId = value.split('id=')
        imageUrl = 'http://drive.google.com/uc?export=view&id=' + fileId[1]
      }

      return imageUrl
    },
    asset (value) {
      let baseUrl = process.env.VUE_APP_BaseURL + '/'

      let assetUrl = baseUrl + value

      return assetUrl
    },
    /**
     * Triggered when the customer search text changes.
     *
     * @param search  {String}   Current search text
     * @param loading {Function} Toggle loading class
     */
    employeeFetchOptions (search) {
      let _this = this

      return new Promise((resolve, reject) => {
        let filters = {
          'searchTerm': search
        }

        EmployeeService.getEmployees(filters).then(res => {
          _this.employeeList = res.data.data
          resolve(_this.employeeList)
        }).catch(err => {
          reject(err)
        })
      })
    }
  },
  computed: {
    fieldsTableDuracao () {
      return [
        {
          name: 'nome',
          title: this.$i18n.t('Descrição'),
          sortField: 'nome',
          formatter: (value) => this.$util.formatEmpty(value)
        },
        {
          name: 'duracao',
          title: 'Duração',
          sortField: 'duracao',
          formatter: (value) => this.$util.formatEmpty(this.showDuracao(value)),
          dataClass: 'text-center',
          titleClass: 'text-center'
        }
      ]
    },
    fieldsTable () {
      return [
        {
          name: 'nome',
          title: this.$i18n.t('Descrição'),
          sortField: 'nome',
          formatter: (value) => this.$util.formatEmpty(value)
        },
        {
          name: 'qtd',
          title: 'Quantidade',
          sortField: 'qtd',
          formatter: (value) => this.$util.formatEmpty(value),
          dataClass: 'text-center',
          titleClass: 'text-center'
        }
      ]
    },
    getActiveFilterList () {
      let activedFilterList = {
        period: null,
        employees: null,
        year: null
      }

      if (this.filters.filPeriodo === 'weekly') {
        activedFilterList = {
          period: null,
          employees: null,
          year: null,
          week: null
        }
      } else if (this.filters.filPeriodo === 'monthly') {
        activedFilterList = {
          period: null,
          employees: null,
          year: null,
          month: null
        }
      }

      return activedFilterList
    },
    perEmployeeData: function () {
      return this.reportData.data.filter(function (object) {
        let verifier = 0
        verifier += object.nf_emitidas
        verifier += object.cobrancas
        verifier += object.qtd_numerarios
        verifier += object.pagamentos

        if (verifier > 0) {
          return true
        }

        return false
      })
    },
    showEmployeeFilter () {
      if (this.user) {
        let showEmployee = false
        if (this.user.roles.filter(role => role === 'ROLE_DIRECTOR').length > 0) {
          showEmployee = true
        } else if (this.user.roles.filter(role => role === 'ROLE_SUPER_ADMIN').length > 0) {
          showEmployee = true
        } else if (this.user.roles.filter(role => role === 'ROLE_FINANTIAL').length > 0) {
          showEmployee = true
        }

        return showEmployee
      } else {
        return false
      }
    },
    user () {
      return this.$store.getters.userInfo || null
    },
    filters () {
      if (this.$Cookie.get('_userStoredFilters_')) {
        return JSON.parse(this.$Cookie.get('_userStoredFilters_'))
      } else {
        return null
      }
    },
    refreshFilter () {
      return this.$store.getters.getRefreshFilter || null
    },
    getPeriodDescription () {
      let period = 'Semanal'

      if (this.filter.period === 'yearly') {
        period = 'Anual'
      } else if (this.filter.period === 'monthly') {
        period = 'Mensal'
      }
      return period
    }
  },
  watch: {
    user: function () {
      this.getReportStatementData()
    },
    refreshFilter: function () {
      if (this.refreshFilter) {
        this.applyFilter()
        this.$store.dispatch('setRefreshFilter', false)
      }
    }
  }
}
</script>

<style scoped>
  .subtitle {
    margin-top: 5px;
  }

  .dark-gray-box {

    background-color: #e3e2e1;

    border-radius: 0 0 38px 38px;
    margin-bottom: 10px;
    padding: 25px;
    padding-left: 75px;
    padding-right: 75px;
    padding-bottom: 1px;
  }

  .dark-gray-box .line {
    margin-top: 10px;
    text-align: center;
    margin-bottom: 27px;
  }

  .label {
    padding-left: 42px;
    padding-top: 7px;
    padding-bottom: 7px;
    background-position: center left;
    background-repeat: no-repeat;
    margin-left: 10px;
    font-size: 11px;
    font-weight: bold;

  }

  .label.air {
    background-image: url("/static/images/report/air_icon.png");
  }

  .label.ship {
    background-image: url("/static/images/report/ship_icon.png");
  }

  .label.lcl {
    background-image: url("/static/images/report/lcl_icon.png");
  }

  .label.fcl {
    background-image: url("/static/images/report/fcl_icon.png");
  }

  .label.insurance {
    background-image: url("/static/images/report/insurance_icon.png");
  }

  .label.green {
    background-image: url("/static/images/report/green_icon.png");
    padding-left: 20px;
  }

  .label.yellow {
    background-image: url("/static/images/report/yellow_icon.png");
    padding-left: 20px;
  }

  .label.red {
    background-image: url("/static/images/report/red_icon.png");
    padding-left: 20px;
  }

  .card-legend, .card-title {
    color: #84754E;
    font-size: 18px;
  }

  .card-title, .card-title b {
    margin-top: 15px;
    margin-bottom: 0;
    font-size: 19px;

  }

  .summary-box .message{
    font-weight: 200;
    font-size: 14px;
    font-family: pluto_sanscond_light;
  }

  .gray-box {
    background-color: #F3F1ED;
    border-radius: 38px 38px 0 0;
    margin-top: 82px;
  }

  /* Sellect Section */
  .row.seller-box {
    margin-bottom: -100px;
  }

  .seller {
    text-align: center;
    top: -100px;

  }

  .seller img {
    border: 10px solid #F3F1ED;
    border-radius: 148px;
    width: 148px;
    position: relative;
    overflow: hidden;
  }

  .seller h2 {
    margin: 12px 0px;
    font-size: 25px;
  }

  .summary-box {
    margin-top: 39px;
  }

  /* Message box */
  .message-box {
    margin: 0 2.8rem;
    color: #84734C;
    font-size: 14px;
  }

  @media (max-width: 576px) {
    .message-box {
      margin: 0 0.8rem;
    }
  }

  /* Details */
  .detail-box .indicator-box {
    background-color: #fefefe;
    border-radius: 0 0 40px 40px;
    padding: 50px 60px;
  }

  .detail-box.first .indicator-box {

    border-radius: 40px 40px 0 0;
    padding: 0 60px;
    padding-top: 50px;
  }

  .detail-box.middle .indicator-box {

    border-radius: 0px 0px 0px 0px;
    padding: 0 60px;
    padding-bottom: 65px;
  }

  .detail-box.first {
    margin-top: 70px;

  }
  .detail-box.last .indicator-box, .detail-box.simple .indicator-box {
    padding-bottom: 45px;

  }

  .detail-box.simple {
    margin-top: 70px;
  }

  .detail-box.simple .indicator-box {
    border-radius: 40px 40px 40px 40px;
  }

  /* Activities */

  .activities h3 {
    margin-top: 0;
    color: #84754E;
    text-align: center;
    font-size: 15px;
    font-weight: bold;
  }

  .activities h3.message {
    margin-top: 45px;
    font-size: 19px;
    font-weight: bold;
  }

  .activities .card-title {
    font-size: 13px !important;
    font-weight: bold;
    color: #84754E;
  }

  .activities .icon {

    background-repeat: no-repeat;
    background-position: center center;
    width: 100%;
    height: 55px;
  }

  .activities .icon.price {
    background-image: url("/static/images/report/price_icon.png");
  }

  .activities .icon.visits {

    background-image: url("/static/images/report/visits_icon.png");
  }

  .activities .icon.presentation {
    background-image: url("/static/images/report/presentations_icon.png");
  }

  .activities .icon.callPhones {
    background-image: url("/static/images/report/cellPhone_icon.png");
  }

  /* right operation / modal label */
  .detail-box .col-md-2 {
    padding: 0;
  }

  .detail-box .col-md-2 p {
    top: 113px;
    font-size: 26px;
    font-weight: 200;
    color: #84754E;
  }

  .section-title, .section-title b {
    font-size: 26px;
    font-weight: 200;
    color: #84754E;
    font-family: 'Pluto Sans';
  }

  .section-title b {
    font-family: 'Pluto Sans Medium';
  }

  .detail-box .col-md-2 p {
    top: 197px;
    left: 0;
    padding-left: 58px;
    padding-top: 18px;
    padding-bottom: 18px;
    font-size: 23px;
    font-weight: 200;
    position: relative;
    background-image: url("/static/images/report/carret.png");
    background-position: -9px center;
    background-repeat: no-repeat;
  }

  .chart-label .medium {
    font-size: 18px
  }

  .card-legend .small {
    font-size: 11px !important;
  }

  .card-title.info {
    margin-top: 59px;
  }

  .card-title.info, .card-legend.info {
    font-size: 15px;
  }

</style>
